<template>
  <div>
    <label @click="input.click()">
      <template name="button">
        <i class="iconfont iconadd-circle"></i>
      </template>
    </label>

    <el-dialog
      title="已上传附件内容(只接收pdf或图片（.png & .jpg & .jpeg格式）)"
      :visible.sync="uploadDialog"
      width="40%"
      append-to-body
      :close-on-click-modal="false"
      :show-close="false"
      v-loading="uploadLoading"
      class="uploadDialog"
    >
      <div>
        <div style="color: #409eff" v-for="(item, index) in files" :key="index">
          {{ item.name }}
        </div>
        <div v-if="recognitionShow" style="color: red">存在不能识别附件，已剔除</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div>
          <el-button type="success" @click="submitFileForm">确 定</el-button>
          <el-button type="warning" plain @click="onCallOff">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    type: {
      type: String,
      default: 'file',
    },
    fields: {
      type: Object,
      default: function () {
        return {
          name: 'attachmentName',
          path: 'attachmentPath',
          type: 'attachmentType',
        }
      },
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      uploadDialog: false,
      uploadLoading: false,
      recognitionShow: false,
      files: [],
      input: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {},
  mounted() {
    this.input = document.createElement('input')
    this.input.setAttribute('type', 'file')
    if (this.multiple) {
      this.input.setAttribute('multiple', 'multiple')
    }
    this.input.addEventListener('change', async e => {
      let files = e.target.files || []
      const arr = []
      this.recognitionShow = false
      for (let index = 0; index < files.length; index++) {
        const v = files[index]
        // 禁止不能识别的附件类型
        if (v.name) {
          let str = v.name.split('.')
          const formatArr = ['pdf', 'png', 'jpg', 'jpeg', 'PDF', 'PNG', 'JPG', 'JPEG']
          if (formatArr.indexOf(str[str.length - 1]) != -1) {
            arr.push(v)
          } else {
            this.recognitionShow = true
          }
        }
      }
      this.files = arr
      this.uploadDialog = true
    })
  },
  methods: {
    onCallOff() {
      this.uploadDialog = false
      this.input.value = ''
    },
    // 提交上传文件
    submitFileForm() {
      this.$confirm('提示', {
        message: '请确认上传发票付款单位为：广东慧图、慧安等公司名称。',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'customClass',
        type: 'warning',
        showClose: false,
      })
        .then(() => {
          this.uploadLoading = true
          if (this.files.length > 0) {
            this.uploadFileFn()
          } else {
            this.errorShow()
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    async uploadFileFn() {
      const arr = []
      for (let i = 0; i < this.files.length; i++) {
        let form = new FormData()
        form.append('file', this.files[i])
        form.append('type', this.type)
        const res = await this.$api.common.uploadFileToinvoice(form)
        if (res.code == 200) {
          arr.push({
            loading: false,
            [`${this.fields.path}`]: res.data.filePath,
            [`${this.fields.name}`]: res.data.fileName,
            [`${this.fields.type}`]: this.type,
            fileType: res.data.fileType, // 发票附件 INVOICE
            invoiceNo: res.data.invoiceNo, // 发票编码
            fileInvoiceNoType: res.data.fileInvoiceNoType, // 发票编码是否已存在  NOT_HAVE-没有,EXIST-已存在
          })
          if (i === this.files.length - 1) {
            this.$emit('uploadFileToinvoice', arr)
            this.uploadLoading = false
            this.uploadDialog = false
            this.input.value = ''
            this.$message({
              type: 'success',
              message: '上传成功',
            })
          }
        } else {
          this.errorShow()
        }
      }
    },
    errorShow() {
      this.$message.error('上传失败，请重新上传')
      this.uploadLoading = false
      this.uploadDialog = false
      this.input.value = ''
    },
  },
}
</script>

<style lang="scss">
.uploadDialog {
  .dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-button {
      width: 120px;
      margin-left: 35px;
    }
  }
}
.el-message-box {
  width: 450px !important;
}
</style>
